import { useEffect, useState } from "react";
import { useAppDispatch } from "../../helpers/hooks";
import { setActivePage, toggleMenu } from "../../store/appSlice";

import MainNavigations from "./MainNavigations";
import MarketingSuites from "./MarketingSuites";
import ContinueYouLeft from "./ContinueYouLeft";
import Campaigns from "./Campaigns";
import Activities from "./Activities";
import './newesthomepage.css';
import './selectedcontenthome.module.css';
import SubscriptionWelcomeOpener from "../PlanPage/SubscriptionPopup/SubscriptionWelcome";
import { Modal } from "antd";
import OnboardingImportBrandPopup from "../Onboarding/OnboardingImportBrandPopup";
import { useLocation } from "react-router-dom";


const SelectedContentHome = () => {
  const dispatch = useAppDispatch();
  const [onboardingImportBrandPopupOpen, setOnboardingImportBrandPopupOpen] = useState(false);
  const { state } = useLocation();


  useEffect(() => {
    dispatch(setActivePage("homepage"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleMenu(true));
  }, [dispatch]);

  return (
    <>
      <div className="flex justify-center items-center w-full  bg-white px-16 overflow-scroll scrollbar-hide">
        <div className="flex-col justify-center items-center md:h-[calc(100vh)] bg-gray-100 rounded-lg">
          <MainNavigations />
          <MarketingSuites />
          <ContinueYouLeft />
          <Campaigns />
          <Activities />
          {state?.subscription_welcome &&
            < SubscriptionWelcomeOpener header={"Welcome to Premium"} body={!state?.skip_trial
              ? `Your current plan includes 250 credits for one month, granting full access to all features. Feel free to upgrade your plan at any time.`
              : "Your plan has been successfully updated. Adjusted features are now in effect."}
              onImportBrand={() => { setOnboardingImportBrandPopupOpen(true) }}
            />
          }
          <Modal
            open={onboardingImportBrandPopupOpen}
            closeIcon={false}
            centered
            footer={null}
            width={960}
            height={640}
          >
            <OnboardingImportBrandPopup />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SelectedContentHome;
